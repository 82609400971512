import VueRouter from "vue-router";
import Vue from "vue";
import store from "@/store/index";
import MerchantRouter from "./merchant_router";
import MerchantStoreRouter from "./merchant_store_router";
import AdminRouter from "./admin_router";
import Common from "./common";
import H5Router from "./h5_router";

const routes = [
    {
        path:'/',
        component:()=>import('@/view/merchant/login.vue'),
    },
    {
        path:'/404',
        component:()=>import("@/view/error/404.vue"),
    },
    ...H5Router,
    ...MerchantRouter,
    ...MerchantStoreRouter,
    ...AdminRouter,
    ...Common,
    {
        path:'/aliapyauth',
        component:()=>import('@/view/notify/alipay/auth.vue'),
    }
];

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);
const router = new VueRouter({
    routes,
    //mode: 'history', // 设置为history模式
    mode: 'hash', // 设置为history模式
});

/**
 * 检查当前访问地址是否存在
 * @param path
 * @param p_path
 * @param list
 * @returns {boolean}
 */
function check_router_path(path ,p_path, list){
    for(let item of list){
        if(p_path+item.path == path){
            return true;
        }
        if(item.children){
            let chek = check_router_path(path ,p_path+item.path+"/" , item.children)
            if(chek){
                return true
            }
        }
    }
    return false;
}


router.beforeEach((to,from,next)=>{
    let check = false
    let path = to.fullPath
    path = (path.split('?'))[0]
    check = check_router_path(path,'',routes)
    if(!check){
        next({
            path:'/404',
        })
        return
    }
    return next()
})

export default router
