/**
 * 数据
 * @type {{}}
 */
const state = {
    login_info:{},
    self_info:{
        id:null,
        account_number:null,
        name:null,
        picture:null,
        last_ip:null,
        agent_id:null,
        pid:null,
        summit_id:null,
        status:null,
        user_id:null,
        role_id:null,
        role_pid:null,
        password_num:null,
        sex:null,
        sellers_id:null,
    },
    //访问令牌
    token:null,
    //登录表单数据
    login_form:{
        //账号
        account_number:"",
        //密码
        password:"",
        //标记
        record:"",
        //验证吗
        captcha_code:'',
        //验证码key
        captcha_key:'',
    },
    system_theme:{
        type:1,
        is_collapse:false,
        theme_list:[
            null,
            {
                name:"简洁样式(明)",
                color: "#606266",
                active_color: "#409EFF",
                background_color: "#FFFFFF",
            },
            {
                name:"传统样式(暗)",
                color: "#FFFFFF",
                active_color: "#FFFFFF",
                background_color: "#303133",
            },
        ],
    },
}
/**
 * 处理同步数据
 * @type {{}}
 */
const mutations ={
    updateLoginInfo(state,data){
        //state.loginInfo = JSON.parse(JSON.stringify(data))
        for(let key in data){
            state.login_info[key] = data[key]
        }
        state.token = data['jwt']
    },
    clearLoginForm(state){
        state.login_form = {
            //账号
            account_number:"",
            //密码
            password:"",
            //标记
            record:"",
            //验证吗
            captcha_code:'',
            //验证码key
            captcha_key:'',
        }
    },
    updateLoginForm(state,payload){
        state.login_form = payload
    },
    clearLogin(state){
        state.login_info = {
            jwt:"",
            name:"",
            account_number:"",
            id:"",
        }
        state.token = ""
    },
    updateToken(state,payload){
        state.login_info = payload
        state.token = payload['jwt']
    },
    updateSelf(state,payload){
        state.self_info = payload
    },
    updateSystemInfo(state,payload){
        state.system_info = payload
    },
    updateMerchantInfo(state,payload){
        state.merchant_info = payload
    },
    updateMerchantEntry(state,payload){
        state.merchant_entry = payload
    },
    updateOutUrl(state,payload){
        state.out_url = payload
    },
    updateThemeType(state,payload){
        state.system_theme.type = payload
    }
}
/**
 * 处理异步数据
 * @type {{}}
 */
const actions = {
    asyncSetLoginInfo({commit} , data){ //异步设置登录信息
        commit("updateLoginInfo",data)
    },
    asyncSetLoginForm({commit} , data){
        commit("updateLoginForm",data)
    },
    asyncSetOutUrl({commit} , data){
        commit("updateOutUrl",data)
    },
    asyncUpdateToken({commit} , data){
        commit("updateToken",data)
    },
    asyncUpdateSelf({commit} , data){
        commit("updateSelf",data)
    },
    asyncClearLoginForm({commit} ){
        commit("clearLoginForm")
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}