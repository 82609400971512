import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false


import router from "./route/index"
import store from "./store/index";
import ws from "./websocket/index.js";
import * as echarts from "echarts";
import {systemInfo} from "./config/serve_config";

import VueAnimateNumber from 'vue-animate-number'
Vue.prototype.$ws = ws
Vue.prototype.$echarts = echarts
Vue.prototype.$conf = systemInfo
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAnimateNumber);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
