export default [

    {
        path:'/h5/login',
        component:()=>import('@/view/h5/login.vue'),
    },
    {
        path: '/h5',
        component:()=>import('@/view/h5/index.vue'),
        meta:{requiresAuth: true},
        children: [
            {
                path: 'home',
                component:()=>import('@/view/h5/home/index.vue'),
                meta:{requiresAuth: true},
            },
            {
                path: 'main',
                component:()=>import('@/view/h5/main/index.vue'),
                meta:{requiresAuth: true},
            },
            {
                path: 'merchant',
                component:()=>import('@/view/h5/merchant/index.vue'),
                meta:{requiresAuth: true},
                children: [
                    {
                        path: '/',
                        component:()=>import('@/view/h5/merchant/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'list',
                        component:()=>import('@/view/h5/merchant/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'control',
                        component:()=>import('@/view/h5/merchant/control.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'config',
                        component:()=>import('@/view/h5/merchant/config.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'user',
                        component:()=>import('@/view/h5/merchant/user.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'payment_gateway',
                        component:()=>import('@/view/h5/merchant/payment_gateway.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'create',
                        component:()=>import('@/view/h5/merchant/create.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'change',
                        component:()=>import('@/view/h5/merchant/change.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'add_white',
                        component:()=>import('@/view/h5/merchant/add_white.vue'),
                        meta:{requiresAuth: true},
                    },
                ]
            },
            {
                path: 'merchant_entry',
                component:()=>import('@/view/h5/merchant_entry/index.vue'),
                meta:{requiresAuth: true},
                children:[
                    {
                        path: '/',
                        component:()=>import('@/view/h5/merchant_entry/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'list',
                        component:()=>import('@/view/h5/merchant_entry/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'details',
                        component:()=>import('@/view/h5/merchant_entry/details.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'process_ok',
                        component:()=>import('@/view/h5/merchant_entry/process_ok.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'process_reject',
                        component:()=>import('@/view/h5/merchant_entry/process_reject.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'add_merc_update',
                        component:()=>import('@/view/h5/merchant_entry/add_merc_update.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'change',
                        component: () => import('@/view/h5/merchant_entry/change.vue'),
                        meta: {requiresAuth: true},
                    },
                    {
                        path: 'add',
                        component: () => import('@/view/h5/merchant_entry/add.vue'),
                        meta: {requiresAuth: true},
                    },
                    {
                        path: 'white_change',
                        component: () => import('@/view/h5/merchant_entry/white_change.vue'),
                        meta: {requiresAuth: true},
                    }

                ]
            },
            {
                path: 'facility',
                component:()=>import('@/view/h5/facility/index.vue'),
                meta:{requiresAuth: true},
                children: [
                    {
                        path: '',
                        component:()=>import('@/view/h5/facility/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'list',
                        component:()=>import('@/view/h5/facility/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'create',
                        component:()=>import('@/view/h5/facility/create.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'update',
                        component:()=>import('@/view/h5/facility/update.vue'),
                        meta:{requiresAuth: true},
                    },
                ]
            },
            {
                path: 'merchant_store',
                component:()=>import('@/view/h5/merchant_store/index.vue'),
                meta:{requiresAuth: true},
                children: [
                    {
                        path: '',
                        component:()=>import('@/view/h5/merchant_store/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'list',
                        component:()=>import('@/view/h5/merchant_store/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'create',
                        component:()=>import('@/view/h5/merchant_store/create.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'update',
                        component:()=>import('@/view/h5/merchant_store/update.vue'),
                        meta:{requiresAuth: true},
                    },
                ]
            },
            {
                path: 'user',
                component:()=>import('@/view/h5/user/index.vue'),
                meta:{requiresAuth: true},
                children: [
                    {
                        path: '',
                        component:()=>import('@/view/h5/user/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'list',
                        component:()=>import('@/view/h5/user/list.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'create',
                        component:()=>import('@/view/h5/user/create.vue'),
                        meta:{requiresAuth: true},
                    },
                    {
                        path: 'update',
                        component:()=>import('@/view/h5/user/update.vue'),
                        meta:{requiresAuth: true},
                    },
                ]
            }
        ]
    }
]