<template>
  <div id="App">
    <router-view />
    <div id="foot" v-if="$store.state.client_data.filings">
      © 2024 {{$store.state.client_data.name}}. <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">桂ICP备 {{$store.state.client_data.filings}} 号</a>
    </div>
  </div>
</template>

<script>
import {systemInfo} from "./config/serve_config"
export default {
  name: 'App',
  mounted() {
    let that = this
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'visible') {
        //console.log('页面现在是可见的');
        if(!that.$ws.getWsStatus()){
          that.$ws.bindWs()
        }
      } else {
        //console.log('页面现在是隐藏的');
      }
    });
    document.title = this.$store.state.client_data.name
    console.log(this.$store.state.client_data.name )
    this.$store.commit('updateSystemInfo',systemInfo)
    this.$ws.initWs()
  },
  components: {

  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 12px;
}
.el-dialog{
  border-radius: 8px !important;
}

.el-message-box{
  border-radius: 8px !important;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 8px;
  border: 2px solid #f5f5f5;
}
#foot{
  position: fixed;
  bottom: 0;
  left: 0;
  height: 32px;
  text-align: center;
  width: 100%;
  color: #606266;
  user-select: none;
}
.limit-box-height{
  max-height: 600px;
  overflow: auto;
  scrollbar-width: none;
}
</style>
