import store from "@/store";

const guard = (to,from,next)=>{
    if(to.meta.requiresAuth){
        if(store.state.merchant.token){
            return next()
        }
        return next({
            path: '/',
        })
    }
    return next()
}

export default [
    {
        path:'/merchant/login',
        component:()=>import('@/view/merchant/login.vue'),
    },
    {
        path:'/merchant/pick_merchant',
        component:()=>import('@/view/merchant/pick_merchant.vue'),
        meta: {requiresAuth: true,title:"商户选择"},
        beforeEnter:guard
    },
    {
        path: '/merchant',
        component: () => import('@/view/merchant/index.vue'),
        meta: {requiresAuth: true},
        beforeEnter:guard,
        children: [
            {
                path:'home',
                component:()=>import('@/view/merchant/home/index.vue'),
                meta:{requiresAuth: true,title:"首页"},
            },
            {
                path:'user',
                component:()=>import('@/view/merchant/user/index.vue'),
                meta:{requiresAuth: true,title:"用户管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/user/list.vue'),
                        meta:{requiresAuth: true,title:"用户列表"},
                    }
                ]
            },
            {
                path:'facility',
                component:()=>import('@/view/merchant/facility/index.vue'),
                meta:{requiresAuth: true,title:"设备管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/facility/list.vue'),
                        meta:{requiresAuth: true,title:"设备列表"},
                    }
                ]
            },
            {
                path:'merchant_store',
                component:()=>import('@/view/merchant/merchant_store/index.vue'),
                meta:{requiresAuth: true,title:"店铺管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/merchant_store/list.vue'),
                        meta:{requiresAuth: true,title:"店铺列表"},
                    }
                ]
            },
            {
                path:'pay_order',
                component:()=>import('@/view/merchant/pay_order/index.vue'),
                meta:{requiresAuth: true,title:"订单管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/pay_order/list.vue'),
                        meta:{requiresAuth: true,title:"订单列表"},
                    }
                ]
            },
            {
                path:'financial_statistics',
                component:()=>import('@/view/merchant/financial_statistics/index.vue'),
                meta:{requiresAuth: true,title:"店铺统计"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/financial_statistics/list.vue'),
                        meta:{requiresAuth: true,title:"统计"},
                    }
                ]
            },
            {
                path:'alipay_enterprise',
                component:()=>import('@/view/merchant/alipay_enterprise/index.vue'),
                meta:{requiresAuth: true,title:"企业管理"},
                children:[
                    {
                        path:'staff',
                        component:()=>import('@/view/merchant/alipay_enterprise/staff.vue'),
                        meta:{requiresAuth: true,title:"员工"},
                    },
                    {
                        path:'department',
                        component:()=>import('@/view/merchant/alipay_enterprise/department.vue'),
                        meta:{requiresAuth: true,title:"部门"},
                    },
                    {
                        path:'enterprise',
                        component:()=>import('@/view/merchant/alipay_enterprise/enterprise.vue'),
                        meta:{requiresAuth: true,title:"企业信息"},
                    },
                    {
                        path:'pay_order',
                        component:()=>import('@/view/merchant/alipay_enterprise/pay_order.vue'),
                        meta:{requiresAuth: true,title:"员工支付订单"},
                    },
                    {
                        path:'reservation_meal',
                        component:()=>import('@/view/merchant/alipay_enterprise/reservation_meal.vue'),
                        meta:{requiresAuth: true,title:"员工预约"},
                    },
                ]
            },
            {
                path:'goods',
                component:()=>import('@/view/merchant/goods/index.vue'),
                meta:{requiresAuth: true,title:"商品管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/goods/list.vue'),
                        meta:{requiresAuth: true,title:"商品列表"},
                    },
                    {
                        path:'column',
                        component:()=>import('@/view/merchant/goods/column.vue'),
                        meta:{requiresAuth: true,title:"类目列表"},
                    },
                    {
                        path:'specifications',
                        component:()=>import('@/view/merchant/goods/specifications.vue'),
                        meta:{requiresAuth: true,title:"规格"},
                    },
                ]
            },
            {
                path:'member',
                component:()=>import('@/view/merchant/member/index.vue'),
                meta:{requiresAuth: true,title:"会员管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/member/list.vue'),
                        meta:{requiresAuth: true,title:"会员列表"},
                    },
                    {
                        path:'template',
                        component:()=>import('@/view/merchant/member/template.vue'),
                        meta:{requiresAuth: true,title:"会员模板"},
                    },
                    {
                        path:'enterprise_member',
                        component:()=>import('@/view/merchant/member/enterprise_member.vue'),
                        meta:{requiresAuth: true,title:"企业会员"},
                    },
                ]
            },
            {
                path:'ticket',
                component:()=>import('@/view/merchant/ticket/index.vue'),
                meta:{requiresAuth: true,title:"优惠券管理"},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/ticket/list.vue'),
                        meta:{requiresAuth: true,title:"优惠券列表"},
                    },
                ]
            },
            {
                path:'reservation_meal',
                component:()=>import('@/view/merchant/reservation_meal/index.vue'),
                meta:{requiresAuth: true},
                children:[
                    {
                        path:'list',
                        component:()=>import('@/view/merchant/reservation_meal/list.vue'),
                        meta:{requiresAuth: true,title:"预约列表"},
                    },
                    {
                        path:'food_menu_plan',
                        component:()=>import('@/view/merchant/reservation_meal/food_menu_plan.vue'),
                        meta:{requiresAuth: true,title:"用餐计划"},
                    },
                    {
                        path:'food_list',
                        component:()=>import('@/view/merchant/reservation_meal/food_list.vue'),
                        meta:{requiresAuth: true,title:"菜品列表"},
                    },
                ]
            },
            {
                path:'set_up',
                component:()=>import('@/view/merchant/set_up/index.vue'),
                meta:{requiresAuth: true,title:"系统设置"},
                children:[
                    {
                        path:'pay_access',
                        component:()=>import('@/view/merchant/set_up/pay_access.vue'),
                        meta:{requiresAuth: true,title:"支付渠道"},
                    },
                    {
                        path:'merchant_center',
                        component:()=>import('@/view/merchant/set_up/merchant_center.vue'),
                        meta:{requiresAuth: true,title:"商户中心"},
                    },
                    {
                        path:'merchant_config',
                        component:()=>import('@/view/merchant/set_up/merchant_config.vue'),
                        meta:{requiresAuth: true,title:"商户配置"},
                    },

                ]
            },

        ]
    }
]